

<template>
  <!-- <common-cmpt
    :showTitle="'舆情报告'"
    :showRefreshDataCmp="false"
    :taskType="'AllUser'"
    :midType="'dept'"
    :columnTitle="'contentLabel'"
    :showFilter="false"
  ></common-cmpt> -->

  <common-cmpt
    :showTitle="'舆情报告'"
    :showRefreshDataCmp="false"
    :taskType="'deptTj'"
    :midType="'dept'"
    :columnTitle="'contentLabel'"
    :showFilter="true"
    :selfColumn="selfColumn"
  ></common-cmpt>
</template>

<script>
// 哪些人为标签爱看哪些文章的标签
// @ is an alias to /src
import _ from "lodash";

// import commonCmpt from "./commonCmpt.vue";

import commonCmpt from "../label/hotCmpt.vue";

export default {
  name: "",
  components: {
    commonCmpt,
  },

  data() {
    return {
      selfColumn: [
        {
          name: "积极的",
          value: "Positive",
        },

        {
          name: "中立及以下",
          value: "Neutral",
        },
      ],
    };
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
